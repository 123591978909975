<template>
	<main class="bg-grid-gray-100/30">
		<section class="constraints flex flex-col items-center justify-center">
			<h1 class="mt-4 max-w-[750px] text-center text-4xl font-bold lg:text-6xl">Write, study and discover with an AI</h1>
			<p class="mt-4 max-w-[600px] text-center text-xl font-light text-neutral-600">
				Chat with your files, generate flashcards, create quizzes and notes, explore other's notes, and much more.
			</p>
			<div class="mt-8">
				<nuxt-link
					class="flex items-center justify-center gap-6 rounded-full bg-primary py-3 text-center font-semibold text-white transition-all hover:brightness-90"
					to="/register">
					Study now
				</nuxt-link>
				<p class="mt-3 text-center text-lg text-neutral-500">Join 20,000+ students from top universities.</p>
			</div>
			<div class="mt-20">
				<div
					:style="{
						transform: headerImageTransformationString,
					}">
					<nuxt-img
						alt="Scholarly"
						class="hidden max-w-[1078px] rounded-2xl border border-gray-100 shadow-sm lg:block"
						format="webp"
						src="/landing/landing_main.png" />
				</div>
			</div>
		</section>
		<section class="mt-32 overflow-x-hidden text-center">
			<h1 class="text-xl font-semibold">Loved by 20,000+ students from top universities</h1>
			<div class="schools mt-4 flex flex-row items-center justify-center gap-12">
				<nuxt-img
					alt="Columbia"
					class="grayscale filter"
					format="webp"
					loading="lazy"
					src="/landing/schools/columbia.png"
					width="100px" />
				<nuxt-img
					alt="George Town"
					class="grayscale filter"
					format="webp"
					loading="lazy"
					src="/landing/schools/george_town.webp"
					width="100px" />
				<nuxt-img
					alt="Harvard"
					class="grayscale filter"
					format="webp"
					loading="lazy"
					src="/landing/schools/harvard.png"
					width="100px" />
				<nuxt-img
					alt="MIT"
					class="grayscale filter"
					format="webp"
					loading="lazy"
					src="/landing/schools/mit.png"
					width="100px" />
				<nuxt-img
					alt="Stanford"
					class="grayscale filter"
					format="webp"
					loading="lazy"
					src="/landing/schools/stanford.png"
					width="100px" />
				<nuxt-img
					alt="University of California, Los Angeles"
					class="grayscale filter"
					format="webp"
					loading="lazy"
					src="/landing/schools/ucla.png"
					width="100px" />
				<nuxt-img
					alt="University of Chicago"
					class="grayscale filter"
					format="webp"
					loading="lazy"
					src="/landing/schools/uni_chicago.png"
					width="100px" />
				<nuxt-img
					alt="USC"
					class="grayscale filter"
					format="webp"
					loading="lazy"
					src="/landing/schools/usc.png"
					width="100px" />
				<nuxt-img
					alt="Yale"
					class="grayscale filter"
					format="webp"
					loading="lazy"
					src="/landing/schools/yale.png"
					width="100px" />
			</div>
		</section>
		<section class="constraints mt-32">
			<div class="grid grid-cols-2 gap-3 lg:grid-cols-5 lg:gap-6">
				<div
					class="group rounded-md border bg-gray-50 px-5 py-3"
					role="button">
					<div class="inline-block rounded-md bg-gray-500 p-2 text-white transition-colors group-hover:bg-primary">
						<flashcards-icon class="h-5 w-5" />
					</div>
					<h1 class="mt-2 text-lg font-semibold">Flashcards</h1>
					<p class="mt-1 text-lg text-neutral-500">Generate flashcards from text, pdfs, & more.</p>
					<nuxt-link
						class="mt-4 flex items-center gap-2 text-base font-medium text-primary opacity-0 transition-opacity group-hover:opacity-100"
						to="/register">
						<span> Try now </span>
						<ArrowRightIcon class="h-4 w-4" />
					</nuxt-link>
				</div>
				<div
					class="group rounded-md border bg-gray-50 px-5 py-3"
					role="button">
					<div class="inline-block rounded-md bg-gray-500 p-2 text-white transition-colors group-hover:bg-primary">
						<AcademicCapIcon class="h-5 w-5" />
					</div>
					<h1 class="mt-2 text-lg font-semibold">Study</h1>
					<p class="mt-1 text-lg text-neutral-500">Generate quiz problems & study with AI.</p>
					<nuxt-link
						class="mt-4 flex items-center gap-2 text-base font-medium text-primary opacity-0 transition-opacity group-hover:opacity-100"
						to="/register">
						<span> Try now </span>
						<ArrowRightIcon class="h-4 w-4" />
					</nuxt-link>
				</div>
				<div
					class="group rounded-md border bg-gray-50 px-5 py-3"
					role="button">
					<div class="inline-block rounded-md bg-gray-500 p-2 text-white transition-colors group-hover:bg-primary">
						<NotebookPen class="h-5 w-5" />
					</div>
					<h1 class="mt-2 text-lg font-semibold">Write</h1>
					<p class="mt-1 text-lg text-neutral-500">An AI that writes with you and for you.</p>
					<nuxt-link
						class="mt-4 flex items-center gap-2 text-base font-medium text-primary opacity-0 transition-opacity group-hover:opacity-100"
						to="/register">
						<span> Try now </span>
						<ArrowRightIcon class="h-4 w-4" />
					</nuxt-link>
				</div>
				<div
					class="group rounded-md border bg-gray-50 px-5 py-3"
					role="button">
					<div class="inline-block rounded-md bg-gray-500 p-2 text-white transition-colors group-hover:bg-primary">
						<BotIcon class="h-5 w-5" />
					</div>
					<h1 class="mt-2 text-lg font-semibold">Chat</h1>
					<p class="mt-1 text-lg text-neutral-500">Chat with your PDFs, images, and more.</p>
					<nuxt-link
						class="mt-4 flex items-center gap-2 text-base font-medium text-primary opacity-0 transition-opacity group-hover:opacity-100"
						to="/register">
						<span> Try now </span>
						<ArrowRightIcon class="h-4 w-4" />
					</nuxt-link>
				</div>
				<div
					class="group rounded-md border bg-gray-50 px-5 py-3"
					role="button">
					<div class="inline-block rounded-md bg-gray-500 p-2 text-white transition-colors group-hover:bg-primary">
						<CompassIcon class="h-5 w-5" />
					</div>
					<h1 class="mt-2 text-lg font-semibold">Explore</h1>
					<p class="mt-1 text-lg text-neutral-500">Explore other's notes and flashcards.</p>
					<nuxt-link
						class="mt-4 flex items-center gap-2 text-base font-medium text-primary opacity-0 transition-opacity group-hover:opacity-100"
						to="/register">
						<span> Try now </span>
						<ArrowRightIcon class="h-4 w-4" />
					</nuxt-link>
				</div>
			</div>
		</section>
		<section class="constraints mt-48">
			<h1 class="text-3xl font-bold">Every student deserves a personal AI Assistant.</h1>
			<h2 class="mt-2 text-xl text-neutral-500">Scholarly is the only platform that has complete AI integration from creation, editing and studying.</h2>
			<div class="mt-12 grid grid-cols-1 gap-3 lg:grid-cols-3 lg:gap-6">
				<div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-blue-400 p-2 text-white transition-colors group-hover:bg-primary">
							<BrainIcon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Memory</p>
							<p class="mt-1 font-light text-neutral-500">Scholarly remembers important things across sessions.</p>
						</div>
					</div>
				</div>
				<div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-blue-400 p-2 text-white transition-colors group-hover:bg-primary">
							<BotIcon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Chat with PDF</p>
							<p class="mt-1 font-light text-neutral-500">Chat with a PDF, extract information, get context & more.</p>
						</div>
					</div>
				</div>
				<div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-blue-400 p-2 text-white transition-colors group-hover:bg-primary">
							<FileTextIcon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Chat with Page</p>
							<p class="mt-1 font-light text-neutral-500">Chat with your notes, documents, and get context-aware responses.</p>
						</div>
					</div>
				</div>
				<div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-blue-400 p-2 text-white transition-colors group-hover:bg-primary">
							<flashcards-icon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Study Flashcards</p>
							<p class="mt-1 font-light text-neutral-500">A personal AI tutor that helps you study flashcards, and quizzes.</p>
						</div>
					</div>
				</div>
				<div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-blue-400 p-2 text-white transition-colors group-hover:bg-primary">
							<BotIcon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Context Aware</p>
							<p class="mt-1 font-light text-neutral-500">Scholarly is context-aware, so we know exactly what you're talking about.</p>
						</div>
					</div>
				</div>
				<div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-blue-400 p-2 text-white transition-colors group-hover:bg-primary">
							<LinkIcon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Link External Files</p>
							<p class="mt-1 font-light text-neutral-500">Link external files to Scholarly Assistant and get context-aware responses.</p>
						</div>
					</div>
				</div>
				<div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-blue-400 p-2 text-white transition-colors group-hover:bg-primary">
							<LanguageIcon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Multi-lingual Support</p>
							<p class="mt-1 font-light text-neutral-500">Chat in your native language, and get responses in your native language.</p>
						</div>
					</div>
				</div>
				<div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-blue-400 p-2 text-white transition-colors group-hover:bg-primary">
							<VideoIcon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Youtube Integration</p>
							<p class="mt-1 font-light text-neutral-500">Ask Scholarly to find videos about any topic, easily.</p>
						</div>
					</div>
				</div>
				<div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-blue-400 p-2 text-white transition-colors group-hover:bg-primary">
							<PencilLine class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Text Completion</p>
							<p class="mt-1 font-light text-neutral-500">Smart, context-aware text completion that helps you write faster and better.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section
			v-if="false"
			class="constraints mt-48">
			<h1 class="text-3xl font-bold">Where knowledge meets AI.</h1>
			<h2 class="mt-2 text-xl text-neutral-500">Utilize works created by other students, to enhance your learning experience.</h2>
			<!-- 
				Section for coming soon
			 -->
		</section>
		<section class="constraints mt-48">
			<div>
				<h1 class="text-4xl font-bold text-black">Advancing Learning</h1>
				<h2 class="mt-2 text-xl text-neutral-500">We believe the future of learning is through practice, and we're here to help you achieve that.</h2>
				<div class="mt-8 grid grid-cols-1 gap-3 lg:grid-cols-3 lg:gap-6">
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-purple-400 p-2 text-white">
							<AcademicCapIcon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Quiz yourself with relevant options</p>
							<p class="mt-1 font-light text-neutral-500">Scholarly converts flashcards into quiz problems, automatically</p>
						</div>
					</div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-purple-400 p-2 text-white">
							<CheckIcon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Question Variety</p>
							<p class="mt-1 font-light text-neutral-500">Try True/False, Multiple Choice & Short Answer questions, all generated by Scholarly.</p>
						</div>
					</div>
					<div class="rounded-lg border border-gray-100 px-5 py-4 shadow-sm">
						<div class="inline-block rounded-md bg-purple-400 p-2 text-white">
							<BrainIcon class="h-5 w-5" />
						</div>
						<div class="mt-1">
							<p class="text-lg font-bold">Get feedback on your answers</p>
							<p class="mt-1 font-light text-neutral-500">You always have a personal AI tutor to help you understand your mistakes.</p>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-8 rounded-md border border-gray-100 shadow-sm">
				<focusable-image>
					<nuxt-img
						alt="Learning"
						class="w-[1280px] rounded-md"
						format="webp"
						loading="lazy"
						src="/landing/advanced_learning_section.png"
						width="1400px" />
				</focusable-image>
			</div>
		</section>
		<faq-section />
	</main>
</template>
<script lang="ts" setup>
import { AcademicCapIcon, CheckIcon, LanguageIcon } from "@heroicons/vue/24/outline";
import { ArrowRightIcon } from "@heroicons/vue/24/solid";
import { BotIcon, BrainIcon, CompassIcon, FileTextIcon, LinkIcon, NotebookPen, PencilLine, VideoIcon } from "lucide-vue-next";

definePageMeta({
	title: "Write, study and discover with an AI - Scholarly",
	layout: "landing",
	name: "index",
});

useHead({
	title: "Write, study and discover with an AI - Scholarly",
});

const headerImageTransformation = reactive({
	perspective: 2000,
	translateX: 0,
	translateY: 0,
	scale: 1,
	rotate: 0,
	rotateX: 10,
	rotateY: 0,
	translateZ: 0,
});

const headerImageTransformationString = computed(() => {
	return `perspective(${headerImageTransformation.perspective}px) translateX(${headerImageTransformation.translateX}px) translateY(${headerImageTransformation.translateY}px) scale(${headerImageTransformation.scale}) rotate(${headerImageTransformation.rotate}deg) rotateX(${headerImageTransformation.rotateX}deg) rotateY(${headerImageTransformation.rotateY}deg) translateZ(${headerImageTransformation.translateZ}px)`;
});

function onScroll() {
	const scrollY = window.scrollY;

	headerImageTransformation.perspective = Math.max(2000 - scrollY * 0.03, 0);
	headerImageTransformation.rotateX = Math.max(10 - scrollY * 0.03, 0);
}

onMounted(() => {
	window.addEventListener("scroll", onScroll);
});

onUnmounted(() => {
	window.removeEventListener("scroll", onScroll);
});
</script>
<style scoped>
.bg-gradient-1 {
	@apply bg-[linear-gradient(45deg,#833ab4,#fd1d1d,#fcb045)];
}

.bg-gradient-2 {
	background: linear-gradient(45deg, #72c6ef 0%, #004e8f 100%);
}

.bg-gradient-3 {
	background: linear-gradient(45deg, #e65c00 0%, #f9d423 100%);
}

.bg-gradient-4 {
	background: linear-gradient(45deg, #bb377d 0%, #fbd3e9 100%);
}

.bg-gradient-5 {
	background: linear-gradient(45deg, #00b09b 0%, #96c93d 100%);
}

/* Schools Fading  */

.schools img {
	opacity: 100%;
}

.schools img:nth-child(1) {
	opacity: 20%;
}

.schools img:nth-child(2) {
	opacity: 40%;
}

.schools img:nth-child(3) {
	opacity: 60%;
}

.schools img:nth-child(4) {
	opacity: 80%;
}

.schools img:nth-last-child(1) {
	opacity: 20%;
}

.schools img:nth-last-child(2) {
	opacity: 40%;
}

.schools img:nth-last-child(3) {
	opacity: 60%;
}

.schools img:nth-last-child(4) {
	opacity: 80%;
}
</style>
